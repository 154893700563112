<template>
  <div>
    <KTCodePreview v-bind:title="'Báo cáo NXT'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="2">
            <date-picker
              placeholder="Chọn tháng"
              class="form-control-sm"
              :config="dpConfigs.monthReport"
              v-model="dpForm.monthReport"
            ></date-picker>
          </b-col>
          <b-col md="4">
            <b-row>
              <!-- <b-button
                variant="warning"
                size="sm"
                class="mr-4"
                @click="excuteReport()"
                >Chạy báo cáo</b-button
              > -->
              <b-button
                variant="primary"
                size="sm"
                class="mr-4"
                @click="reportClick()"
                >Xuất báo cáo</b-button
              >
              <!-- <b-button variant="success" size="sm" @click="runUpdateData()"
                >Cập nhật giá vốn</b-button
              > -->
            </b-row>
          </b-col>
          <b-col md="3">
            <b-input
              v-model="searchProduct"
              size="sm"
              placeholder="Tìm theo sản phẩm"
              v-on:keyup.enter="onFilter()"
            />
          </b-col>
          <b-col md="2">
            <b-form-select
              size="sm"
              class="select-style"
              v-model="selectedProductType"
              :options="listProductType"
              value-field="id"
              text-field="name"
            />
          </b-col>
          <b-col md="1">
            <b-button
              variant="primary"
              size="sm"
              @click="onFilter()"
              style="width: inherit"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <div class="d-flex d-inline justify-content-end">
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-dark"></i>
            Đơn giá
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-primary"></i>
            Đơn giá VAT
          </span>
        </div>
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
          show-empty
          responsive
        >
          <template slot="thead-top">
            <tr>
              <th class="text-center" colspan="1">Sản phẩm</th>
              <th class="text-center" colspan="3">Đầu kỳ</th>
              <th class="text-center" colspan="3">Phát sinh trong kỳ nhập</th>
              <th class="text-center" colspan="3">Phát sinh trong kỳ xuất</th>
              <th class="text-center" colspan="3">Cuối kỳ</th>
            </tr>
          </template>
          <template #empty="scope">
            <h5 class="text-center">Chưa có dữ liệu</h5>
          </template>
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
          </template>
          <template v-slot:cell(productName)="row">
            <span
              v-if="row.item.barCode"
              v-text="row.item.barCode"
              style="white-space: normal; font-size: 12px; font-weight: 500"
            ></span>
            <br v-if="row.item.barCode" />
            <span
              v-if="row.item.productCode"
              style="font-size: 12px; font-weight: 500; margin-bottom: 0"
            >
              ( {{ row.item.productCode }} )
            </span>
            <br v-if="row.item.productCode" />
            <span
              v-text="row.item.productName"
              style="white-space: normal"
            ></span>
            <br v-if="row.item.productVat" />
            <small v-if="row.item.productVat">
              <b class="text-warning">VAT: </b><b>{{ row.item.productVat }}</b>
            </small>
          </template>
          <template v-slot:cell(openningPrice)="row">
            <span
              v-text="convertPrice(row.item.openningPrice)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.openningVatPrice)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(openningValue)="row">
            <span
              v-text="convertPrice(row.item.openningValue)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.openningVatValue)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(importPrice)="row">
            <span
              v-text="convertPrice(row.item.importPrice)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.importVatPrice)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(valueIn)="row">
            <span
              v-text="convertPrice(row.item.valueIn)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.valueVatIn)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(exportPrice)="row">
            <span
              v-text="convertPrice(row.item.exportPrice)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.exportVatPrice)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(valueOut)="row">
            <span
              v-text="convertPrice(row.item.valueOut)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.valueVatOut)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(closingPrice)="row">
            <span
              v-text="convertPrice(row.item.closingPrice)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.closingVatPrice)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
          <template v-slot:cell(closingValue)="row">
            <span
              v-text="convertPrice(row.item.closingValue)"
              style="white-space: normal"
              class="text-dark"
            ></span>
            <br />
            <span
              v-text="convertPrice(row.item.closingVatValue)"
              style="white-space: normal"
              class="font-weight-bold text-primary"
            ></span>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="filterReport"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { BASE_URL } from '../../../utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios';
import ApiService from '@/core/services/api.service';
import {
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
} from '../../../utils/common';
import fileDownload from '../../../utils/file-download';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      dpForm: {
        monthReport: '',
      },
      dpConfigs: {
        monthReport: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'MM-yyyy',
      language: vi,
      fields: [
        {
          key: 'productName',
          label: 'Tên',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '18%',
          },
        },
        {
          key: 'openningInStock',
          label: 'Tồn',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '4%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'openningPrice',
          label: 'Đơn giá',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'openningValue',
          label: 'Giá trị',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'quantityIn',
          label: 'SL',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '4%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'importPrice',
          label: 'Đơn giá',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'valueIn',
          label: 'Giá trị',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'quantityOut',
          label: 'SL',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '4%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'exportPrice',
          label: 'Đơn giá',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'valueOut',
          label: 'Giá trị',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'closingInStock',
          label: 'Tồn',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '4%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'closingPrice',
          label: 'Đơn giá',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'closingValue',
          label: 'Giá trị',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
      ],
      listItem: [],
      searchProduct: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      listProductType: [
        { id: null, name: 'Thể loại sản phẩm' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      selectedProductType: null,
      isReportExisted: false,
      numberOfPage: 0,
    };
  },
  methods: {
    convertPrice,
    excuteReport: async function () {
      const monthReport = this.dpForm.monthReport
        ? moment(this.dpForm.monthReport, 'MM/YYYY').format('yyyy-MM')
        : null;
      if (!monthReport) {
        return makeToastFaile('Vui lòng chọn tháng xem báo cáo NXT!');
      }
      await this.checkExitedReport(monthReport);
      if (this.isReportExisted) {
        this.showWarningExcuteReportAlert();
        return;
      }
      this.onCallAPIExcuteReport();
    },
    onCallAPIExcuteReport: async function () {
      const monthReport = this.dpForm.monthReport
        ? moment(this.dpForm.monthReport, 'MM/YYYY').format('yyyy-MM')
        : null;
      await ApiService.query('inventory-summary-reports/excute', {
        params: {
          monthReport: monthReport,
        },
      })
        .then((response) => {
          makeToastSuccess(response.data.message);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    reportClick: async function () {
      const monthReport = this.dpForm.monthReport
        ? moment(this.dpForm.monthReport, 'MM/YYYY').format('yyyy-MM')
        : null;
      if (!monthReport) {
        return makeToastFaile('Vui lòng chọn tháng xuất báo cáo NXT!');
      }
      await this.checkExitedReport(monthReport);
      if (!this.isReportExisted) {
        return makeToastFaile(
          'Báo cáo tháng chưa tồn tại, vui lòng chạy báo cáo tháng để xuất báo cáo!'
        );
      }
      const url = `${BASE_URL}inventory-summary-reports/report?monthReport=${monthReport}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    filterReport: async function () {
      const monthReport = this.dpForm.monthReport
        ? moment(this.dpForm.monthReport, 'MM/YYYY').format('yyyy-MM')
        : null;
      if (!monthReport) {
        return makeToastFaile('Vui lòng chọn tháng xem báo cáo NXT!');
      }
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const params = {
        monthReport: monthReport,
        searchProduct: this.searchProduct,
        productType: this.selectedProductType,
        page: this.page,
        limit: 30,
      };
      this.onLoading = true;
      await ApiService.query('inventory-summary-reports/', {
        params,
      })
        .then((response) => {
          this.listItem = response.data.data.dataset;
          this.numberOfPage = response.data.data.totalPage;
          this.totalItem = response.data.data.totalRow;
          this.onLoading = false;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
          this.onLoading = false;
        });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'report-inventory-summaries',
      });
      this.filterReport();
    },
    checkExitedReport: async function (monthReport) {
      this.isReportExisted = false;
      const params = {
        monthReport: monthReport,
        searchProduct: this.searchProduct,
        productType: this.selectedProductType,
        page: 1,
        limit: 1,
      };
      await ApiService.query('inventory-summary-reports/', {
        params,
      })
        .then((response) => {
          this.isReportExisted = response.data.data.dataset.length
            ? true
            : false;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    showWarningExcuteReportAlert: function () {
      Swal.fire({
        title: 'Cảnh báo dữ liệu đã tồn tại!',
        text: 'Dữ liệu báo cáo NXT tháng đã tồn tại, bạn có chắc muốn chạy lại báo cáo không?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.onCallAPIExcuteReport();
        }
      });
    },
    runUpdateData: async function () {
      const monthReport = this.dpForm.monthReport
        ? moment(this.dpForm.monthReport, 'MM/YYYY').format('yyyy-MM')
        : null;
      if (!monthReport) {
        return makeToastFaile('Vui lòng chọn tháng xem báo cáo NXT!');
      }
      await this.checkExitedReport(monthReport);
      if (!this.isReportExisted) {
        makeToastFaile(
          'Báo cáo tháng chưa tồn tại, vui lòng chạy báo cáo tháng trước khi cập nhật giá vốn!'
        );
        return;
      }
      await ApiService.query('inventory-summary-reports/update-data', {
        params: {
          monthReport: monthReport,
        },
      })
        .then((response) => {
          makeToastSuccess(response.data.message);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo XNT', route: 'report-inventory-summaries' },
    ]);
  },
  created() {},
  components: {
    KTCodePreview,
    datePicker,
    VclTable,
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link {
  font-weight: 500;
  font-size: 1rem;
}

.customPagination /deep/ .page-item .page-link:hover i {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}
</style>
